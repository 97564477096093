<template>
  <b-card
    class="card-custom gutter-b"
    no-body
  >
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <b-card-title>
        <h2 class="card-label">
          {{ trans('nav.aside.sessions.all') }}
          <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('sessions.index.desc') }}
          </span>
        </h2>
      </b-card-title>
      <!-- card-toolbar placeholder: add toolbar actions here :: <div class="card-toolbar">-->
    </div>
    <div class="card-body" style="min-height: 80px;">
      [En liste over design-økter kommer her]
    </div>
  </b-card>
</template>


<script>
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { onBeforeMount, onMounted } from '@vue/composition-api'

  export default {
    name: 'UsersIndex',
    setup (props, { root }) {
      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('nav.aside.sessions.title'),
            route: 'users'
          },
          { title: trans('nav.aside.sessions.all') }
        ])
      })

      return {
        trans,
        transChoice
      }
    }
  }
</script>

<style lang="scss">
  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
